
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { checkPermission } from '@/core/helpers/helps';
import { useI18n } from 'vue-i18n';
import { computed, ref, onMounted, reactive, nextTick } from 'vue';
import { useStore } from 'vuex';
import images from '@/assets/ts/images.js';
import { Actions } from '@/store/enums/StoreEnums';
import { DrawerModeEnum } from '@/store/modules/ChannelModule';
import { toastError, toastSuccess } from '@/core/services/Alert';
import { checkPermissionUpdate } from '@/core/helpers/helps';

export default {
  name: 'workspace-environtment',
  setup() {
    const { t } = useI18n();
    const store = useStore();
    let formPost = reactive({
            id : null,
            tenant_id : store.getters.getTenantId,
            name: '',
            link: '',
    });

    setCurrentPageBreadcrumbs(('workspacePage.workspaceTitle'), [
      { title: ('common.setting'), path: '/setting' },
    ]);

    const drawer = computed({
      get: () => store.getters.getChannelDrawerState,
      set: (v) => store.dispatch(Actions.UPDATE_CHANNEL_DRAWER, v),
    });

    const onSubmit = (values, res) => {            
          const payload = {
            params : {
              id : formPost.id,
            },
            data : {
              name : values.name,
              link : values.link,
              tenant_id : formPost.tenant_id
            }
          };
          
          if (drawerStatus.value === 'add') {
              store
                  .dispatch(Actions.CREATE_WORKSPACE, payload.data)
                  .then((response) => {
                      toastSuccess(`${t('common.success')} ${t('common.add')}`)
                      getWorkspaceList();
                  })
                  .catch((err) => {
                      toastError(`${t('common.failed')} ${t('common.add')}`)
                  })
                  .finally(() => {
                      closeDrawer();
                  });
              closeDrawer();
          } else if (drawerStatus.value === 'edit') {
              store
                  .dispatch(Actions.UPDATE_WORKSPACE, payload)
                  .then((response) => {
                      toastSuccess(`${t('common.success')} ${t('common.edit')}`)
                      getWorkspaceList();
                  })
                  .catch((err) => {
                      toastError(`${t('common.failed')} ${t('common.edit')}`)
                      console.log("value error", err);
                  })
                  .finally(() => {
                      closeDrawer();
                  });
              closeDrawer();
          };
      }
    
      const deleteWorkspace = (data) => {
        store.dispatch(Actions.DELETE_WORKSPACE, data)
            .then((response) => {
                toastSuccess(`${t('common.success')} ${t('common.delete')} `)
                getWorkspaceList();
            })
            .catch((err) => {
                toastError(`${t('common.failed')} ${t('common.delete')} `)
                console.log("value error", err);
            })
            .finally(() => {
                console.log('sukses')
            });
    }

    const getWorkspace = computed(() => {
      return store.getters.getListWorkspace;
    });

    const drawerStatus = computed(() => store.getters.getChannelDrawerMode);

    const closeDrawer = () => {
      formPost.name ='';
      formPost.link = '';

      store.dispatch(Actions.UPDATE_CHANNEL_DRAWER, false);
    };

    const selectedChannel = ref('');
    const workspaceName = ref('');
    const workspaceUrl = ref('');

    const openDrawer = (data, mode = 'add') => {
      if (mode === 'edit') {
          formPost.id = data._id;
          formPost.name = data.name;
          formPost.link = data.link && data.link;
      }

      store.dispatch(Actions.UPDATE_CHANNEL_DRAWER, true);
      store.dispatch(Actions.UPDATE_CHANNEL_DRAWER_MODE, mode);
    };

    const getWorkspaceList = async () => {
        await nextTick()
        store.dispatch(
            Actions.GET_WORKSPACE_LIST, formPost
        );
    }

    onMounted(() => {
      getWorkspaceList()
    });

    return {
      checkPermission,
      drawer,
      images,
      selectedChannel,
      closeDrawer,
      getWorkspace,
      DrawerModeEnum,
      workspaceName,
      workspaceUrl,
      openDrawer,
      drawerStatus,
      formPost,
      getWorkspaceList,
      onSubmit,
      deleteWorkspace,
      checkPermissionUpdate
    };
  },
};
