
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { checkPermission, checkPermissionUpdate } from "@/core/helpers/helps";
import { useI18n } from "vue-i18n";
import { computed, nextTick, onMounted, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import images from "@/assets/ts/images.js";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { DrawerModeEnum } from "@/store/modules/EarningStatusModules";
import { Form } from "vee-validate";
import { useRoute } from "vue-router";
import { toastSuccess, toastError } from "@/core/services/Alert";

export default {
	name: "access-token-seeting",
	setup() {
		const { t } = useI18n();
		const store = useStore();
		const route = useRoute();
		const tokens = computed(() => store.getters.getTokenPBI);
		let formPBIToken = reactive({
			id: null,
			client_id: null,
			client_secret: null,
		});

		const drawer = computed({
			get: () => store.getters.getAccessTokenDrawer,
			set: (val) => store.commit(Mutations.SET_POWERBI_TOKEN_DRAWER, val),
		});

		const drawerStatus = ref("edit");

		function closeDrawer() {
			store.commit(Mutations.SET_POWERBI_TOKEN_DRAWER, false);
			formPBIToken.id = null;
			formPBIToken.client_id = null;
			formPBIToken.client_secret = null;

			getTokenList();
		}

		function openDrawer(data, mode = "add") {
			console.log("datass ", data);

			if (mode === "edit") {
				formPBIToken.id = data.id;
				formPBIToken.client_id = data.POWER_BI_CLIENT_ID;
				formPBIToken.client_secret = data.POWER_BI_CLIENT_SECRET;
			}

			store.commit(Mutations.SET_POWERBI_TOKEN_DRAWER, true);
			drawerStatus.value = mode;
		}

		onMounted(() => {
			getTokenList();
			setCurrentPageBreadcrumbs("tokenSetting", [
				{ title: "common.setting", path: "/setting" },
				{ title: "admin", path: "/setting/admin-setting/access-token" },
			]);
		});

		const getTokenList = async () => {
			await nextTick();
			store.dispatch(Actions.GET_POWERBI_TOKEN);
		};

    	const formValidation = ref();
		const onSubmit = (values) => {
			const payload = {
				id: values.id,
				client_id: values.client_id,
				client_secret: values.client_secret,
			};
			const validating: any = formValidation.value;

			validating.validate((valid: any) => {
				if (!valid) return false;
				if (drawerStatus.value === "add") {
					// store
					// 	.dispatch(Actions.CREATE_ASET_DIMENSION, payload)
					// 	.then((response) => {
					// 		toastSuccess(
					// 			`${t("common.success")} ${t("common.add")} ${
					// 				t("tokenSetting")
					// 			}`
					// 		);
					// 		console.log("response create", response);
					// 		closeDrawer();
					// 	})
					// 	.catch((err) => {
					// 		toastError(
					// 			`${t("common.failed")} ${t("common.add")} ${
					// 				t("tokenSetting")
					// 			}`
					// 		);
					// 		console.log("value error", err);
					// 	})
					// 	.finally(() => {
					// 		closeDrawer();
					// 	});
				} else if (drawerStatus.value === "edit") {
					store
						.dispatch(Actions.EDIT_POWERBI_TOKEN, payload)
						.then((response) => {
							toastSuccess(
								`${t("common.success")} ${t("common.edit")} ${t("tokenSetting")}`
							);
							console.log("response edit", response);
							closeDrawer();
						})
						.catch((err) => {
							toastError(
								`${t("common.failed")} ${t("common.edit")} ${t("tokenSetting")}`
							);
							console.log("value error", err);
						})
						.finally(() => {
							closeDrawer();
						});
				}
			})
		};

		return {
			checkPermission,
			checkPermissionUpdate,
			drawer,
			images,
			closeDrawer,
			DrawerModeEnum,
			openDrawer,
			drawerStatus,
			getTokenList,
			onSubmit,
			Form,
			formPBIToken,
			tokens,
			formValidation,
		};
	},
};
