
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { checkPermission } from "@/core/helpers/helps";
import { useI18n } from "vue-i18n";
import { computed, ref, onMounted, reactive, nextTick, watch } from "vue";
import { useStore } from "vuex";
import images from "@/assets/ts/images.js";
import { Actions } from "@/store/enums/StoreEnums";
import { DrawerModeEnum } from "@/store/modules/ChannelModule";
import { toastError, toastSuccess } from "@/core/services/Alert";
import ElInputVeeValidate from "@/components/forms/ElInputVeeValidate.vue";
import { checkPermissionUpdate } from "@/core/helpers/helps";

export default {
  name: "channel-environtment",
  components: { ElInputVeeValidate },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    let formPost = reactive({
      id: null,
      name: "",
      email: "",
      phone_number: "",
      address: "",
      tenant_id: "",
      workspace_id: "",
    });

    setCurrentPageBreadcrumbs("channelPage.channelTitle", [
      { title: "common.setting", path: "/setting" },
    ]);

    const drawer = computed({
      get: () => store.getters.getChannelDrawerState,
      set: (v) => store.dispatch(Actions.UPDATE_CHANNEL_DRAWER, v),
    });

    const onSubmit = (values, res) => {
      const workspace_detail = store.getters.getListWorkspace.filter(
        (data) => data._id === values.workspace_id
        );
        console.log('masuk', workspace_detail[0].tenant_id)
      const payload = {
        params: {
          id: formPost.id,
        },
        data: {
          name: values.name,
          email: values.email,
          alias: "ANPER",
          phone_number: values.phone_number,
          address: values.address,
          workspace_id: values.workspace_id,
          tenant_id:
            workspace_detail.length > -1 ? workspace_detail[0].tenant_id._id : "",
        },
      };

      if (drawerStatus.value === "add") {
        store
          .dispatch(Actions.CREATE_CHANNEL, payload.data)
          .then((response) => {
            toastSuccess(`${t("common.success")} ${t("common.add")}`);
            getChannelList();
          })
          .catch((err) => {
            toastError(`${t("common.failed")} ${t("common.add")}`);
          })
          .finally(() => {
            closeDrawer();
          });
        closeDrawer();
      } else if (drawerStatus.value === "edit") {
        store
          .dispatch(Actions.UPDATE_CHANNEL_LIST, payload)
          .then((response) => {
            toastSuccess(`${t("common.success")} ${t("common.edit")}`);
            getChannelList();
          })
          .catch((err) => {
            toastError(`${t("common.failed")} ${t("common.edit")}`);
            console.log("value error", err);
          })
          .finally(() => {
            closeDrawer();
          });
        closeDrawer();
      }
    };

    const deleteChannel = (data) => {
      store
        .dispatch(Actions.DELETE_CHANNEL, data)
        .then((response) => {
          toastSuccess(`${t("common.success")} ${t("common.delete")} `);
          console.log("response delete", response);
          getChannelList();
        })
        .catch((err) => {
          toastError(`${t("common.failed")} ${t("common.delete")} `);
          console.log("value error", err);
        })
        .finally(() => {
          console.log("sukses");
        });
    };

    const getWorkspace = computed(() => {
      return store.getters.getListWorkspace;
    });

    const getListChannel = computed(() => {
      return store.getters.getListChannel;
    });

    const drawerStatus = computed(() => store.getters.getChannelDrawerMode);

    const closeDrawer = () => {
      store.dispatch(Actions.UPDATE_CHANNEL_DRAWER, false);
      formPost.name = "";
      formPost.email = "";
      formPost.phone_number = "";
      formPost.address = "";
      formPost.workspace_id = "";
      formPost.tenant_id = "";
    };

    const selectedChannel = ref("");
    const channelName = ref("");
    const tableData = [
      {
        date: "2016-05-03",
        name: "Channel A",
        total: "11",
        state: "California",
        city: "San Francisco",
        address: "3650 21st St, San Francisco",
        zip: "CA 94114",
        family: [
          {
            name: "Channel A",
          },
          {
            name: "Channel B",
          },
          {
            name: "Channel C",
          },
        ],
      },
      {
        date: "2016-05-02",
        name: "Channel B",
        total: "14",
        state: "California",
        city: "San Francisco",
        address: "3650 21st St, San Francisco",
        zip: "CA 94114",
        family: [
          {
            name: "Channel A",
          },
          {
            name: "Channel B",
          },
          {
            name: "Channel C",
          },
        ],
      },
      {
        date: "2016-05-04",
        name: "Channel C",
        total: "3",
        state: "California",
        city: "San Francisco",
        address: "3650 21st St, San Francisco",
        zip: "CA 94114",
        family: [
          {
            name: "Channel A",
          },
          {
            name: "Channel B",
          },
          {
            name: "Channel C",
          },
        ],
      },
    ];

    const openDrawer = (data, mode = "add") => {
      if (mode === "edit") {
        formPost.id = data._id;
        formPost.name = data.name;
        formPost.address = data.address;
        formPost.email = data.email;
        formPost.phone_number = data.phone_number;
        formPost.workspace_id = data.workspace_id;
      }

      store.dispatch(Actions.UPDATE_CHANNEL_DRAWER, true);
      store.dispatch(Actions.UPDATE_CHANNEL_DRAWER_MODE, mode);
    };

    const getWorkspaceList = async () => {
      await nextTick();
      store.dispatch(Actions.GET_WORKSPACE_LIST, {
        tenant_id: process.env.VUE_APP_TENANT_ID,
      });
    };

    const getChannelList = async () => {
      await nextTick();
      store.dispatch(Actions.GET_CHANNEL_LIST);
    };

    onMounted(() => {
      getChannelList();
      getWorkspaceList();
    });

    const validateNumberPhone = ($event) => {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    };

    return {
      validateNumberPhone,
      getChannelList,
      getWorkspaceList,
      checkPermission,
      drawer,
      images,
      selectedChannel,
      closeDrawer,
      tableData,
      DrawerModeEnum,
      getWorkspace,
      getListChannel,
      channelName,
      openDrawer,
      drawerStatus,
      formPost,
      onSubmit,
      deleteChannel,
      checkPermissionUpdate,
    };
  },
};
