
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { checkPermission, checkPermissionUpdate } from "@/core/helpers/helps";
import { useI18n } from "vue-i18n";
import { computed, ref, onMounted, reactive, nextTick, watch } from "vue";
import { useStore } from "vuex";
import images from "@/assets/ts/images.js";
import { Actions } from "@/store/enums/StoreEnums";
import {
  PageCurrencyList,
  DocumentForm,
  DrawerModeEnum,
} from "@/store/modules/AssetsDataMasterModule";
import { toastError, toastSuccess } from "@/core/services/Alert";
import { useRoute } from "vue-router";

export default {
  name: "category-currency",
  setup() {
    const { t } = useI18n();
    const { meta } = useRoute();
    const store = useStore();

    /**
     * Load data to client view
     */
    const pageData = reactive<PageCurrencyList>({
      masterList: computed(() => getPageDataList()),
    });

    /**
     * page information and breadcrumb load, pageName get from meta data in routes
     */
    const pageInfo = reactive({
      add: meta.pageName + ".addLabel",
      title: meta.pageName + ".title",
      description: meta.pageName + ".description",
      textMode: meta.pageName + ".textMode",
    });
    const activeWorkspace = computed(() => store.getters.getActiveWorkspace);

    setCurrentPageBreadcrumbs(`${pageInfo.title}`, [
      {
        title: "common.setting",
        path: "/setting",
      },
    ]);

    /**
     * init object form data post or put
     */
    let formPost = reactive<DocumentForm>({
      _id: "",
      name: "",
      channel_id: "",
      formBuilder: [
        {
          name: "channel_id",
          label: "Pilih Channel",
          type: "select",
          option: computed(() => store.getters.getListChannel),
          isDisabled: false,
          mode: ["add", "edit"],
        },
        {
          name: "name",
          label: "Jenis Dokumen",
          type: "text",
          isDisabled: false,
          mode: ["add", "edit"],
        },
      ],
    });

    /**
     * Drawer set show or hide
     */
    const drawer = computed({
      get: () => store.getters.getCategoryDrawerState,
      set: (v) => store.dispatch(Actions.UPDATE_CATEGORY_ASSETS_DRAWER, v),
    });
    const drawerMode = computed(() => store.getters.getCategoryDrawerMode);
    const closeDrawer = () => {
      store.dispatch(Actions.UPDATE_CATEGORY_ASSETS_DRAWER, false);
      formPost._id = "";
      formPost.name = "";
      formPost.channel_id = "";
    };

    const openDrawer = (data, mode = "add") => {
      if (mode === "edit") {
        for (const obj in data) {
          formPost[obj] = data[obj];
        }

        console.log(formPost);
      }

      store.dispatch(Actions.UPDATE_CATEGORY_ASSETS_DRAWER, true);
      store.dispatch(Actions.UPDATE_CATEGORY_ASSETS_DRAWER_MODE, mode);
    };

    /**
     * handling posting or updating data from input drawer
     * @param values passing data from form input
     * @param res respon data
     */
    const onSubmit = (values, res) => {
      const payload = {
        params: {
          id: formPost._id,
        },
        data: {},
      };
      /**
       * props data from form drawer to payload object
       */
      for (const obj in values) {
        const formBuilder = formPost.formBuilder.filter((el) => el.name == obj);
        if (
          formBuilder.length > 0 &&
          formBuilder[0].mode.includes(drawerMode.value)
        ) {
          payload.data = {
            ...payload.data,
            [obj]: values[obj],
            workspace_id: activeWorkspace.value._id,
          };
        }
      }

      if (drawerMode.value === "add") {
        store
          .dispatch(Actions.CREATE_DOCUMENT, payload.data)
          .then((response) => {
            toastSuccess(`${t("common.success")} ${t("common.add")}`);
            actionMaster();
          })
          .catch((err) => {
            toastError(`${t("common.failed")} ${t("common.add")}`);
          })
          .finally(() => {
            closeDrawer();
          });
        closeDrawer();
      } else if (drawerMode.value === "edit") {
        store
          .dispatch(Actions.UPDATE_DOCUMENT, payload)
          .then((response) => {
            toastSuccess(`${t("common.success")} ${t("common.edit")}`);
            actionMaster();
          })
          .catch((err) => {
            toastError(`${t("common.failed")} ${t("common.edit")}`);
            console.log("value error", err);
          })
          .finally(() => {
            closeDrawer();
          });
        closeDrawer();
      }
    };

    /**
     *
     * @param id id row data form selected list
     */
    const deleteMasterData = (id) => {
      store
        .dispatch(Actions.DELETE_DOCUMENT, id)
        .then((response) => {
          toastSuccess(`${t("common.success")} ${t("common.delete")} `);
          actionMaster();
        })
        .catch((err) => {
          toastError(`${t("common.failed")} ${t("common.delete")} `);
          console.log("value error", err);
        })
        .finally(() => {
          console.log("sukses");
        });
    };

    const getPageDataList = () => {
      const tmp: any[] = [];
      let getRes = store.getters.getListDocument;
      getRes.map((data, index) => {
        tmp.push({
          ...data,
        });
      });

      return tmp;
    };

    /**
     * action master load master data halaman dari store
     */
    const actionMaster = async () => {
      await nextTick();
      store.dispatch(Actions.GET_DOCUMENT, activeWorkspace.value._id);
    };
    /**
     * run function on init page
     */
    onMounted(() => {
      getPageDataList();
      actionMaster();
      store.dispatch(Actions.GET_CHANNEL_LIST);
    });

    return {
      meta,
      pageInfo,
      getPageDataList,
      actionMaster,
      pageData,
      checkPermission,
      checkPermissionUpdate,
      drawer,
      images,
      closeDrawer,
      openDrawer,
      drawerMode,
      formPost,
      onSubmit,
      deleteMasterData,
      DrawerModeEnum,
    };
  },
};
