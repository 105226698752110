/**
 * Currency codes (ISO 4217)
 * referensi list data docs.1010data.com/1010dataReferenceManual/DataTypesAndFormats/currencyUnitCodes.html
 */
interface defindeCurrencyObject {
    symbol:string;
    name: string;
    symbol_native: string;
    decimal_digits: number;
    rounding: number;
    _id: string;
    description: string;
}

export const currencyList: Array<defindeCurrencyObject> = [
    {
       symbol: "$",
       name: "US Dollar",
       symbol_native: "$",
       decimal_digits: 2,
       rounding: 0,
       _id: "USD",
       description: "US dollars"
     },
    {
       symbol: "CA$",
       name: "Canadian Dollar",
       symbol_native: "$",
       decimal_digits: 2,
       rounding: 0,
       _id: "CAD",
       description: "Canadian dollars"
     },
    {
       symbol: "€",
       name: "Euro",
       symbol_native: "€",
       decimal_digits: 2,
       rounding: 0,
       _id: "EUR",
       description: "euros"
     },
    {
       symbol: "AED",
       name: "United Arab Emirates Dirham",
       symbol_native: "",
       decimal_digits: 2,
       rounding: 0,
       _id: "AED",
       description: "UAE dirhams"
     },
    {
       symbol: "Af",
       name: "Afghan Afghani",
       symbol_native: "؋",
       decimal_digits: 0,
       rounding: 0,
       _id: "AFN",
       description: "Afghan Afghanis"
     },
    {
       symbol: "ALL",
       name: "Albanian Lek",
       symbol_native: "Lek",
       decimal_digits: 0,
       rounding: 0,
       _id: "ALL",
       description: "Albanian lekë"
     },
    {
       symbol: "AMD",
       name: "Armenian Dram",
       symbol_native: "դր.",
       decimal_digits: 0,
       rounding: 0,
       _id: "AMD",
       description: "Armenian drams"
     },
    {
       symbol: "AR$",
       name: "Argentine Peso",
       symbol_native: "$",
       decimal_digits: 2,
       rounding: 0,
       _id: "ARS",
       description: "Argentine pesos"
     },
    {
       symbol: "AU$",
       name: "Australian Dollar",
       symbol_native: "$",
       decimal_digits: 2,
       rounding: 0,
       _id: "AUD",
       description: "Australian dollars"
     },
    {
       symbol: "man.",
       name: "Azerbaijani Manat",
       symbol_native: "ман.",
       decimal_digits: 2,
       rounding: 0,
       _id: "AZN",
       description: "Azerbaijani manats"
     },
    {
       symbol: "KM",
       name: "Bosnia-Herzegovina Convertible Mark",
       symbol_native: "KM",
       decimal_digits: 2,
       rounding: 0,
       _id: "BAM",
       description: "Bosnia-Herzegovina convertible marks"
     },
    {
       symbol: "Tk",
       name: "Bangladeshi Taka",
       symbol_native: "৳",
       decimal_digits: 2,
       rounding: 0,
       _id: "BDT",
       description: "Bangladeshi takas"
     },
    {
       symbol: "BGN",
       name: "Bulgarian Lev",
       symbol_native: "лв.",
       decimal_digits: 2,
       rounding: 0,
       _id: "BGN",
       description: "Bulgarian leva"
     },
    {
       symbol: "BD",
       name: "Bahraini Dinar",
       symbol_native: "",
       decimal_digits: 3,
       rounding: 0,
       _id: "BHD",
       description: "Bahraini dinars"
     },
    {
       symbol: "FBu",
       name: "Burundian Franc",
       symbol_native: "FBu",
       decimal_digits: 0,
       rounding: 0,
       _id: "BIF",
       description: "Burundian francs"
     },
    {
       symbol: "BN$",
       name: "Brunei Dollar",
       symbol_native: "$",
       decimal_digits: 2,
       rounding: 0,
       _id: "BND",
       description: "Brunei dollars"
     },
    {
       symbol: "Bs",
       name: "Bolivian Boliviano",
       symbol_native: "Bs",
       decimal_digits: 2,
       rounding: 0,
       _id: "BOB",
       description: "Bolivian bolivianos"
     },
    {
       symbol: "R$",
       name: "Brazilian Real",
       symbol_native: "R$",
       decimal_digits: 2,
       rounding: 0,
       _id: "BRL",
       description: "Brazilian reals"
     },
    {
       symbol: "BWP",
       name: "Botswanan Pula",
       symbol_native: "P",
       decimal_digits: 2,
       rounding: 0,
       _id: "BWP",
       description: "Botswanan pulas"
     },
    {
       symbol: "Br",
       name: "Belarusian Ruble",
       symbol_native: "руб.",
       decimal_digits: 2,
       rounding: 0,
       _id: "BYN",
       description: "Belarusian rubles"
     },
    {
       symbol: "BZ$",
       name: "Belize Dollar",
       symbol_native: "$",
       decimal_digits: 2,
       rounding: 0,
       _id: "BZD",
       description: "Belize dollars"
     },
    {
       symbol: "CDF",
       name: "Congolese Franc",
       symbol_native: "FrCD",
       decimal_digits: 2,
       rounding: 0,
       _id: "CDF",
       description: "Congolese francs"
     },
    {
       symbol: "CHF",
       name: "Swiss Franc",
       symbol_native: "CHF",
       decimal_digits: 2,
       rounding: 0.05,
       _id: "CHF",
       description: "Swiss francs"
     },
    {
       symbol: "CL$",
       name: "Chilean Peso",
       symbol_native: "$",
       decimal_digits: 0,
       rounding: 0,
       _id: "CLP",
       description: "Chilean pesos"
     },
    {
       symbol: "CN¥",
       name: "Chinese Yuan",
       symbol_native: "CN¥",
       decimal_digits: 2,
       rounding: 0,
       _id: "CNY",
       description: "Chinese yuan"
     },
    {
       symbol: "CO$",
       name: "Colombian Peso",
       symbol_native: "$",
       decimal_digits: 0,
       rounding: 0,
       _id: "COP",
       description: "Colombian pesos"
     },
    {
       symbol: "₡",
       name: "Costa Rican Colón",
       symbol_native: "₡",
       decimal_digits: 0,
       rounding: 0,
       _id: "CRC",
       description: "Costa Rican colóns"
     },
    {
       symbol: "CV$",
       name: "Cape Verdean Escudo",
       symbol_native: "CV$",
       decimal_digits: 2,
       rounding: 0,
       _id: "CVE",
       description: "Cape Verdean escudos"
     },
    {
       symbol: "Kč",
       name: "Czech Republic Koruna",
       symbol_native: "Kč",
       decimal_digits: 2,
       rounding: 0,
       _id: "CZK",
       description: "Czech Republic korunas"
     },
    {
       symbol: "Fdj",
       name: "Djiboutian Franc",
       symbol_native: "Fdj",
       decimal_digits: 0,
       rounding: 0,
       _id: "DJF",
       description: "Djiboutian francs"
     },
    {
       symbol: "Dkr",
       name: "Danish Krone",
       symbol_native: "kr",
       decimal_digits: 2,
       rounding: 0,
       _id: "DKK",
       description: "Danish kroner"
     },
    {
       symbol: "RD$",
       name: "Dominican Peso",
       symbol_native: "RD$",
       decimal_digits: 2,
       rounding: 0,
       _id: "DOP",
       description: "Dominican pesos"
     },
    {
       symbol: "DA",
       name: "Algerian Dinar",
       symbol_native: "",
       decimal_digits: 2,
       rounding: 0,
       _id: "DZD",
       description: "Algerian dinars"
     },
    {
       symbol: "Ekr",
       name: "Estonian Kroon",
       symbol_native: "kr",
       decimal_digits: 2,
       rounding: 0,
       _id: "EEK",
       description: "Estonian kroons"
     },
    {
       symbol: "EGP",
       name: "Egyptian Pound",
       symbol_native: "",
       decimal_digits: 2,
       rounding: 0,
       _id: "EGP",
       description: "Egyptian pounds"
     },
    {
       symbol: "Nfk",
       name: "Eritrean Nakfa",
       symbol_native: "Nfk",
       decimal_digits: 2,
       rounding: 0,
       _id: "ERN",
       description: "Eritrean nakfas"
     },
    {
       symbol: "Br",
       name: "Ethiopian Birr",
       symbol_native: "Br",
       decimal_digits: 2,
       rounding: 0,
       _id: "ETB",
       description: "Ethiopian birrs"
     },
    {
       symbol: "£",
       name: "British Pound Sterling",
       symbol_native: "£",
       decimal_digits: 2,
       rounding: 0,
       _id: "GBP",
       description: "British pounds sterling"
     },
    {
       symbol: "GEL",
       name: "Georgian Lari",
       symbol_native: "GEL",
       decimal_digits: 2,
       rounding: 0,
       _id: "GEL",
       description: "Georgian laris"
     },
    {
       symbol: "GH₵",
       name: "Ghanaian Cedi",
       symbol_native: "GH₵",
       decimal_digits: 2,
       rounding: 0,
       _id: "GHS",
       description: "Ghanaian cedis"
     },
    {
       symbol: "FG",
       name: "Guinean Franc",
       symbol_native: "FG",
       decimal_digits: 0,
       rounding: 0,
       _id: "GNF",
       description: "Guinean francs"
     },
    {
       symbol: "GTQ",
       name: "Guatemalan Quetzal",
       symbol_native: "Q",
       decimal_digits: 2,
       rounding: 0,
       _id: "GTQ",
       description: "Guatemalan quetzals"
     },
    {
       symbol: "HK$",
       name: "Hong Kong Dollar",
       symbol_native: "$",
       decimal_digits: 2,
       rounding: 0,
       _id: "HKD",
       description: "Hong Kong dollars"
     },
    {
       symbol: "HNL",
       name: "Honduran Lempira",
       symbol_native: "L",
       decimal_digits: 2,
       rounding: 0,
       _id: "HNL",
       description: "Honduran lempiras"
     },
    {
       symbol: "kn",
       name: "Croatian Kuna",
       symbol_native: "kn",
       decimal_digits: 2,
       rounding: 0,
       _id: "HRK",
       description: "Croatian kunas"
     },
    {
       symbol: "Ft",
       name: "Hungarian Forint",
       symbol_native: "Ft",
       decimal_digits: 0,
       rounding: 0,
       _id: "HUF",
       description: "Hungarian forints"
     },
    {
       symbol: "Rp",
       name: "Indonesian Rupiah",
       symbol_native: "Rp",
       decimal_digits: 0,
       rounding: 0,
       _id: "IDR",
       description: "Indonesian rupiahs"
     },
    {
       symbol: "₪",
       name: "Israeli New Sheqel",
       symbol_native: "₪",
       decimal_digits: 2,
       rounding: 0,
       _id: "ILS",
       description: "Israeli new sheqels"
     },
    {
       symbol: "Rs",
       name: "Indian Rupee",
       symbol_native: "টকা",
       decimal_digits: 2,
       rounding: 0,
       _id: "INR",
       description: "Indian rupees"
     },
    {
       symbol: "IQD",
       name: "Iraqi Dinar",
       symbol_native: "",
       decimal_digits: 0,
       rounding: 0,
       _id: "IQD",
       description: "Iraqi dinars"
     },
    {
       symbol: "IRR",
       name: "Iranian Rial",
       symbol_native: "﷼",
       decimal_digits: 0,
       rounding: 0,
       _id: "IRR",
       description: "Iranian rials"
     },
    {
       symbol: "Ikr",
       name: "Icelandic Króna",
       symbol_native: "kr",
       decimal_digits: 0,
       rounding: 0,
       _id: "ISK",
       description: "Icelandic krónur"
     },
    {
       symbol: "J$",
       name: "Jamaican Dollar",
       symbol_native: "$",
       decimal_digits: 2,
       rounding: 0,
       _id: "JMD",
       description: "Jamaican dollars"
     },
    {
       symbol: "JD",
       name: "Jordanian Dinar",
       symbol_native: "",
       decimal_digits: 3,
       rounding: 0,
       _id: "JOD",
       description: "Jordanian dinars"
     },
    {
       symbol: "¥",
       name: "Japanese Yen",
       symbol_native: "￥",
       decimal_digits: 0,
       rounding: 0,
       _id: "JPY",
       description: "Japanese yen"
     },
    {
       symbol: "Ksh",
       name: "Kenyan Shilling",
       symbol_native: "Ksh",
       decimal_digits: 2,
       rounding: 0,
       _id: "KES",
       description: "Kenyan shillings"
     },
    {
       symbol: "KHR",
       name: "Cambodian Riel",
       symbol_native: "៛",
       decimal_digits: 2,
       rounding: 0,
       _id: "KHR",
       description: "Cambodian riels"
     },
    {
       symbol: "CF",
       name: "Comorian Franc",
       symbol_native: "FC",
       decimal_digits: 0,
       rounding: 0,
       _id: "KMF",
       description: "Comorian francs"
     },
    {
       symbol: "₩",
       name: "South Korean Won",
       symbol_native: "₩",
       decimal_digits: 0,
       rounding: 0,
       _id: "KRW",
       description: "South Korean won"
     },
    {
       symbol: "KD",
       name: "Kuwaiti Dinar",
       symbol_native: "",
       decimal_digits: 3,
       rounding: 0,
       _id: "KWD",
       description: "Kuwaiti dinars"
     },
    {
       symbol: "KZT",
       name: "Kazakhstani Tenge",
       symbol_native: "тңг.",
       decimal_digits: 2,
       rounding: 0,
       _id: "KZT",
       description: "Kazakhstani tenges"
     },
    {
       symbol: "L.L.",
       name: "Lebanese Pound",
       symbol_native: "",
       decimal_digits: 0,
       rounding: 0,
       _id: "LBP",
       description: "Lebanese pounds"
     },
    {
       symbol: "SLRs",
       name: "Sri Lankan Rupee",
       symbol_native: "SL Re",
       decimal_digits: 2,
       rounding: 0,
       _id: "LKR",
       description: "Sri Lankan rupees"
     },
    {
       symbol: "Lt",
       name: "Lithuanian Litas",
       symbol_native: "Lt",
       decimal_digits: 2,
       rounding: 0,
       _id: "LTL",
       description: "Lithuanian litai"
     },
    {
       symbol: "Ls",
       name: "Latvian Lats",
       symbol_native: "Ls",
       decimal_digits: 2,
       rounding: 0,
       _id: "LVL",
       description: "Latvian lati"
     },
    {
       symbol: "LD",
       name: "Libyan Dinar",
       symbol_native: "",
       decimal_digits: 3,
       rounding: 0,
       _id: "LYD",
       description: "Libyan dinars"
     },
    {
       symbol: "MAD",
       name: "Moroccan Dirham",
       symbol_native: "",
       decimal_digits: 2,
       rounding: 0,
       _id: "MAD",
       description: "Moroccan dirhams"
     },
    {
       symbol: "MDL",
       name: "Moldovan Leu",
       symbol_native: "MDL",
       decimal_digits: 2,
       rounding: 0,
       _id: "MDL",
       description: "Moldovan lei"
     },
    {
       symbol: "MGA",
       name: "Malagasy Ariary",
       symbol_native: "MGA",
       decimal_digits: 0,
       rounding: 0,
       _id: "MGA",
       description: "Malagasy Ariaries"
     },
    {
       symbol: "MKD",
       name: "Macedonian Denar",
       symbol_native: "MKD",
       decimal_digits: 2,
       rounding: 0,
       _id: "MKD",
       description: "Macedonian denari"
     },
    {
       symbol: "MMK",
       name: "Myanma Kyat",
       symbol_native: "K",
       decimal_digits: 0,
       rounding: 0,
       _id: "MMK",
       description: "Myanma kyats"
     },
    {
       symbol: "MOP$",
       name: "Macanese Pataca",
       symbol_native: "MOP$",
       decimal_digits: 2,
       rounding: 0,
       _id: "MOP",
       description: "Macanese patacas"
     },
    {
       symbol: "MURs",
       name: "Mauritian Rupee",
       symbol_native: "MURs",
       decimal_digits: 0,
       rounding: 0,
       _id: "MUR",
       description: "Mauritian rupees"
     },
    {
       symbol: "MX$",
       name: "Mexican Peso",
       symbol_native: "$",
       decimal_digits: 2,
       rounding: 0,
       _id: "MXN",
       description: "Mexican pesos"
     },
    {
       symbol: "RM",
       name: "Malaysian Ringgit",
       symbol_native: "RM",
       decimal_digits: 2,
       rounding: 0,
       _id: "MYR",
       description: "Malaysian ringgits"
     },
    {
       symbol: "MTn",
       name: "Mozambican Metical",
       symbol_native: "MTn",
       decimal_digits: 2,
       rounding: 0,
       _id: "MZN",
       description: "Mozambican meticals"
     },
    {
       symbol: "N$",
       name: "Namibian Dollar",
       symbol_native: "N$",
       decimal_digits: 2,
       rounding: 0,
       _id: "NAD",
       description: "Namibian dollars"
     },
    {
       symbol: "₦",
       name: "Nigerian Naira",
       symbol_native: "₦",
       decimal_digits: 2,
       rounding: 0,
       _id: "NGN",
       description: "Nigerian nairas"
     },
    {
       symbol: "C$",
       name: "Nicaraguan Córdoba",
       symbol_native: "C$",
       decimal_digits: 2,
       rounding: 0,
       _id: "NIO",
       description: "Nicaraguan córdobas"
     },
    {
       symbol: "Nkr",
       name: "Norwegian Krone",
       symbol_native: "kr",
       decimal_digits: 2,
       rounding: 0,
       _id: "NOK",
       description: "Norwegian kroner"
     },
    {
       symbol: "NPRs",
       name: "Nepalese Rupee",
       symbol_native: "नेरू",
       decimal_digits: 2,
       rounding: 0,
       _id: "NPR",
       description: "Nepalese rupees"
     },
    {
       symbol: "NZ$",
       name: "New Zealand Dollar",
       symbol_native: "$",
       decimal_digits: 2,
       rounding: 0,
       _id: "NZD",
       description: "New Zealand dollars"
     },
    {
       symbol: "OMR",
       name: "Omani Rial",
       symbol_native: "",
       decimal_digits: 3,
       rounding: 0,
       _id: "OMR",
       description: "Omani rials"
     },
    {
       symbol: "B/.",
       name: "Panamanian Balboa",
       symbol_native: "B/.",
       decimal_digits: 2,
       rounding: 0,
       _id: "PAB",
       description: "Panamanian balboas"
     },
    {
       symbol: "S/.",
       name: "Peruvian Nuevo Sol",
       symbol_native: "S/.",
       decimal_digits: 2,
       rounding: 0,
       _id: "PEN",
       description: "Peruvian nuevos soles"
     },
    {
       symbol: "₱",
       name: "Philippine Peso",
       symbol_native: "₱",
       decimal_digits: 2,
       rounding: 0,
       _id: "PHP",
       description: "Philippine pesos"
     },
    {
       symbol: "PKRs",
       name: "Pakistani Rupee",
       symbol_native: "₨",
       decimal_digits: 0,
       rounding: 0,
       _id: "PKR",
       description: "Pakistani rupees"
     },
    {
       symbol: "zł",
       name: "Polish Zloty",
       symbol_native: "zł",
       decimal_digits: 2,
       rounding: 0,
       _id: "PLN",
       description: "Polish zlotys"
     },
    {
       symbol: "₲",
       name: "Paraguayan Guarani",
       symbol_native: "₲",
       decimal_digits: 0,
       rounding: 0,
       _id: "PYG",
       description: "Paraguayan guaranis"
     },
    {
       symbol: "QR",
       name: "Qatari Rial",
       symbol_native: "",
       decimal_digits: 2,
       rounding: 0,
       _id: "QAR",
       description: "Qatari rials"
     },
    {
       symbol: "RON",
       name: "Romanian Leu",
       symbol_native: "RON",
       decimal_digits: 2,
       rounding: 0,
       _id: "RON",
       description: "Romanian lei"
     },
    {
       symbol: "din.",
       name: "Serbian Dinar",
       symbol_native: "дин.",
       decimal_digits: 0,
       rounding: 0,
       _id: "RSD",
       description: "Serbian dinars"
     },
    {
       symbol: "RUB",
       name: "Russian Ruble",
       symbol_native: "₽.",
       decimal_digits: 2,
       rounding: 0,
       _id: "RUB",
       description: "Russian rubles"
     },
    {
       symbol: "RWF",
       name: "Rwandan Franc",
       symbol_native: "FR",
       decimal_digits: 0,
       rounding: 0,
       _id: "RWF",
       description: "Rwandan francs"
     },
    {
       symbol: "SR",
       name: "Saudi Riyal",
       symbol_native: "",
       decimal_digits: 2,
       rounding: 0,
       _id: "SAR",
       description: "Saudi riyals"
     },
    {
       symbol: "SDG",
       name: "Sudanese Pound",
       symbol_native: "SDG",
       decimal_digits: 2,
       rounding: 0,
       _id: "SDG",
       description: "Sudanese pounds"
     },
    {
       symbol: "Skr",
       name: "Swedish Krona",
       symbol_native: "kr",
       decimal_digits: 2,
       rounding: 0,
       _id: "SEK",
       description: "Swedish kronor"
     },
    {
       symbol: "S$",
       name: "Singapore Dollar",
       symbol_native: "$",
       decimal_digits: 2,
       rounding: 0,
       _id: "SGD",
       description: "Singapore dollars"
     },
    {
       symbol: "Ssh",
       name: "Somali Shilling",
       symbol_native: "Ssh",
       decimal_digits: 0,
       rounding: 0,
       _id: "SOS",
       description: "Somali shillings"
     },
    {
       symbol: "SY£",
       name: "Syrian Pound",
       symbol_native: "",
       decimal_digits: 0,
       rounding: 0,
       _id: "SYP",
       description: "Syrian pounds"
     },
    {
       symbol: "฿",
       name: "Thai Baht",
       symbol_native: "฿",
       decimal_digits: 2,
       rounding: 0,
       _id: "THB",
       description: "Thai baht"
     },
    {
       symbol: "DT",
       name: "Tunisian Dinar",
       symbol_native: "",
       decimal_digits: 3,
       rounding: 0,
       _id: "TND",
       description: "Tunisian dinars"
     },
    {
       symbol: "T$",
       name: "Tongan Paʻanga",
       symbol_native: "T$",
       decimal_digits: 2,
       rounding: 0,
       _id: "TOP",
       description: "Tongan paʻanga"
     },
    {
       symbol: "TL",
       name: "Turkish Lira",
       symbol_native: "TL",
       decimal_digits: 2,
       rounding: 0,
       _id: "TRY",
       description: "Turkish Lira"
     },
    {
       symbol: "TT$",
       name: "Trinidad and Tobago Dollar",
       symbol_native: "$",
       decimal_digits: 2,
       rounding: 0,
       _id: "TTD",
       description: "Trinidad and Tobago dollars"
     },
    {
       symbol: "NT$",
       name: "New Taiwan Dollar",
       symbol_native: "NT$",
       decimal_digits: 2,
       rounding: 0,
       _id: "TWD",
       description: "New Taiwan dollars"
     },
    {
       symbol: "TSh",
       name: "Tanzanian Shilling",
       symbol_native: "TSh",
       decimal_digits: 0,
       rounding: 0,
       _id: "TZS",
       description: "Tanzanian shillings"
     },
    {
       symbol: "₴",
       name: "Ukrainian Hryvnia",
       symbol_native: "₴",
       decimal_digits: 2,
       rounding: 0,
       _id: "UAH",
       description: "Ukrainian hryvnias"
     },
    {
       symbol: "USh",
       name: "Ugandan Shilling",
       symbol_native: "USh",
       decimal_digits: 0,
       rounding: 0,
       _id: "UGX",
       description: "Ugandan shillings"
     },
    {
       symbol: "$U",
       name: "Uruguayan Peso",
       symbol_native: "$",
       decimal_digits: 2,
       rounding: 0,
       _id: "UYU",
       description: "Uruguayan pesos"
     },
    {
       symbol: "UZS",
       name: "Uzbekistan Som",
       symbol_native: "UZS",
       decimal_digits: 0,
       rounding: 0,
       _id: "UZS",
       description: "Uzbekistan som"
     },
    {
       symbol: "Bs.F.",
       name: "Venezuelan Bolívar",
       symbol_native: "Bs.F.",
       decimal_digits: 2,
       rounding: 0,
       _id: "VEF",
       description: "Venezuelan bolívars"
     },
    {
       symbol: "₫",
       name: "Vietnamese Dong",
       symbol_native: "₫",
       decimal_digits: 0,
       rounding: 0,
       _id: "VND",
       description: "Vietnamese dong"
     },
    {
       symbol: "FCFA",
       name: "CFA Franc BEAC",
       symbol_native: "FCFA",
       decimal_digits: 0,
       rounding: 0,
       _id: "XAF",
       description: "CFA francs BEAC"
     },
    {
       symbol: "CFA",
       name: "CFA Franc BCEAO",
       symbol_native: "CFA",
       decimal_digits: 0,
       rounding: 0,
       _id: "XOF",
       description: "CFA francs BCEAO"
     },
    {
       symbol: "YR",
       name: "Yemeni Rial",
       symbol_native: "",
       decimal_digits: 0,
       rounding: 0,
       _id: "YER",
       description: "Yemeni rials"
     },
    {
       symbol: "R",
       name: "South African Rand",
       symbol_native: "R",
       decimal_digits: 2,
       rounding: 0,
       _id: "ZAR",
       description: "South African rand"
     },
    {
       symbol: "ZK",
       name: "Zambian Kwacha",
       symbol_native: "ZK",
       decimal_digits: 0,
       rounding: 0,
       _id: "ZMK",
       description: "Zambian kwachas"
     },
    {
       symbol: "ZWL$",
       name: "Zimbabwean Dollar",
       symbol_native: "ZWL$",
       decimal_digits: 0,
       rounding: 0,
       _id: "ZWL",
       description: "Zimbabwean Dollar"
     }
   ];