
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { checkPermission } from "@/core/helpers/helps";
import { useI18n } from "vue-i18n";

import * as Yup from "yup";
import { Field, Form, ErrorMessage, configure } from "vee-validate";
import { computed, ref, onMounted } from "vue";
import ElInputVeeValidate from "@/components/forms/ElInputVeeValidate.vue";
import { useStore } from "vuex";
import images from "@/assets/ts/images.js";
import { Actions } from "@/store/enums/StoreEnums";
import { DrawerModeEnum } from "@/store/modules/AssetsDataMasterModule";

export default {
  name: "general-setting",
  components: {
    ElInputVeeValidate,
    Form,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const submitButton = ref<HTMLElement | null>(null);

    setCurrentPageBreadcrumbs(("categoryAssetPage.categoryTitle"), [
      { title: ("common.setting"), path: "/setting" },
    ]);

    const addAssetSchema = Yup.object().shape({
      description: Yup.string().required().label("Description"),
      category: Yup.string().required().label("Jenis Aset"),
    });

    const onSubmit = (values, res) => {
      const payload = {
        name: values.category,
        description: values.description,
        code: values.category.substring(0, 3),
        status_category_id: "631577ec8c130d3fa0415fe8",
      };

      submitButton.value?.setAttribute("data-kt-indicator", "on");
      console.log("values", values);

      store
        .dispatch(Actions.CREATE_CATEGORY_ASSETS, payload)
        .then((response) => {
          console.log("response create", response);
          closeDrawer();
        })
        .catch((err) => {
          console.log("value error", err);
        })
        .finally(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
          closeDrawer();
        });
    };

    const drawer = computed({
      get: () => store.getters.getCategoryDrawerState,
      set: (v) => store.dispatch(Actions.UPDATE_CATEGORY_ASSETS_DRAWER, v),
    });

    const drawerStatus = computed(() => store.getters.getCategoryDrawerMode);

    const closeDrawer = () => {
      store.dispatch(Actions.UPDATE_CATEGORY_ASSETS_DRAWER, false);
    }

    function handleEditSuccess() {
      closeDrawer();
      store.dispatch(Actions.GET_USER_LIST, store.getters.usersFilter);
    }

    const assetCategory = ref("");
    const listassetCategory = [
      { id: 1, name: "Pembelian / Investasi", code: "PI" },
      { id: 2, name: "Hibah", code: "HI" },
      { id: 3, name: "Hasil Perjanjian", code: "HP" },
      { id: 4, name: "Putusan Pengadilan", code: "PP" },
      { id: 5, name: "Penyertaan Modal Negara", code: "PMN" },
    ];

    function openDrawer(member, mode = "add") {
      store.dispatch(Actions.UPDATE_CATEGORY_ASSETS_DRAWER, true);
      store.dispatch(Actions.UPDATE_CATEGORY_ASSETS_DRAWER_MODE, mode);
    }

    const getJenisAset = computed(() => {
      return store.getters.getListCategory;
    });
    
    const deleteCategory = (data) => {
        store.dispatch(Actions.DELETE_CATEGORY_ASSETS, data)
        .then((response) => {
          console.log("response delete", response);
        })
        .catch((err) => {
          console.log("value error", err);
        })
        .finally(() => {
          console.log('sukses')
        });
    }
    onMounted(() => {
      // sementara hardcode
      store.dispatch(
        Actions.GET_ASSET_CATEGORY_LIST,
        "631577ec8c130d3fa0415fe8"
      );
    });

    return {
      checkPermission,
      deleteCategory,
      drawer,
      getJenisAset,
      images,
      assetCategory,
      closeDrawer,
      listassetCategory,
      onSubmit,
      DrawerModeEnum,
      openDrawer,
      drawerStatus,
      addAssetSchema,
      submitButton,
    };
  },
};
